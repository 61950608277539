//
// Web Components.
// Layout.
// -------------------

@import "../elevation/mixins";
@import "./variables";

// Shared Styles.
// -------------------

.layout,
.layout__header,
.layout__main,
.layout__subscription,
.layout__footer,
.footer__row,
.footer__section,
.footer__social-media {
  display: flex;
  box-sizing: border-box;
}

.layout,
.layout__header,
.layout__main,
.layout__subscription,
.layout__footer {
  flex-direction: column;
}

// Layout Container.
// -------------------

.layout {
  position: relative;
}

// Layout Header.
// -------------------

.layout__header {
  align-items: center;
  justify-content: center;

  @media (max-width: $wc-layout-breakpoint - 1) {
    padding: $wc-layout-header-padding-sm;
  }

  @media (min-width: $wc-layout-breakpoint) {
    padding: $wc-layout-header-padding;
  }

  &--homepage {
    z-index: 1;
    min-height: 590px;
    min-height: 77.5vh;
    color:rgba(black, .84);
    background-color: $wc-light-color;
  }

  &--storytime {
    z-index: 1;
    min-height: 360px;
    min-height: 50vh;
    color:rgba(black, .84);
    background-color: $wc-light-color;
  }
}

// Layout Main Content.
// -------------------

.layout__main {
  flex-grow: 1;
  flex-shrink: 0;
  background-color: $wc-layout-main-bg-color;

  @media (max-width: $wc-layout-breakpoint - 1) {
    padding: $wc-layout-main-padding-sm;
  }

  @media (min-width: $wc-layout-breakpoint) {
    padding: $wc-layout-main-padding;
  }
}

// Layout Footer Area.
// -------------------

.layout__footer {
  padding-top: $wc-layout-footer-padding-y;
  padding-bottom: $wc-layout-footer-padding-y;
  color: $wc-layout-footer-fg-color;
  font-size: $wc-layout-footer-font-size;
  letter-spacing: $wc-layout-footer-letter-spacing;
  line-height: $wc-layout-footer-line-height;
  background-color: $wc-layout-footer-bg-color;

  @media (max-width: $wc-layout-breakpoint - 1) {
    padding-left: $wc-layout-footer-padding-x-sm;
    padding-right: $wc-layout-footer-padding-x-sm;
  }

  @media (min-width: $wc-layout-breakpoint) {
    padding-left: $wc-layout-footer-padding-x;
    padding-right: $wc-layout-footer-padding-x;
  }
}

// Layout Footer Row.
// -------------------

.footer__row {
  @media (max-width: $wc-layout-breakpoint - 1) {
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: $wc-layout-breakpoint) {
    align-items: flex-start;
  }
}

// Layout Footer Row Section.
// -------------------

.footer__section {
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;

  @media (max-width: $wc-layout-breakpoint - 1) {
    align-items: center;
    text-align: center;
  }

  @media (min-width: $wc-layout-breakpoint) {
    &--start {
      align-items: flex-start;
    }

    &--end {
      align-items: flex-end;
    }
  }
}

// Layout Footer Social Media.
// -------------------

.footer__social-media {
  align-items: center;
  justify-content: center;

  > * {
    margin: .5rem;
  }
}

// Layout Footer Links.
// -------------------

.footer__link {
  display: block;
  font-weight: 500;
  opacity: .56;

  &,
  &:hover,
  &:active,
  &:focus {
    color: white;
    text-decoration: none;
  }

  &:hover,
  &:active,
  &:focus {
    opacity: .84;
  }
}

// Layout Subscription.
// -------------------

.layout__subscription {
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $wc-layout-breakpoint) {
    width: $wc-layout-breakpoint - 8;
  }
}

.subscription__title {
  margin-bottom: .5rem;
  text-align: center;
}

.subscription__textfield {
  @media (max-width: $wc-layout-breakpoint - 1) {
    width: 100%;
  }

  @media (min-width: $wc-layout-breakpoint) {
    width: 75%;
  }
}

.subscription__action {
  margin-top: .5rem;
  margin-bottom: 1.5rem;

  @media (max-width: $wc-layout-breakpoint - 1) {
    width: 100%;
  }

  @media (min-width: $wc-layout-breakpoint) {
    width: 40%;
  }
}

.subscription__link {
  font-size: .75rem;
  font-weight: 500;
  opacity: .64;

  &,
  &:hover,
  &:active,
  &:focus {
    color: #212121;
  }

  &:hover,
  &:active,
  &:focus {
    opacity: .86;
  }
}
