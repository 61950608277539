//
// Web Components.
// Card.
// -------------------

@import "./variables";
@import "../elevation/mixins";

// Card Style.
// -------------------

.card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: rgba($wc-card-text-color, .87);
  font-family: inherit;
  background-color: $wc-card-background-color;
  border-radius: $wc-card-border-radius;

  &--expanded {
    flex-grow: 1;
    // flex-shrink: 0;
    width: 100%;
    // height: 100%;
  }

  &--no-overflow {
    overflow: hidden;
  }

  &:not(&--no-shadow) {
    @include wc-elevation(2);
  }

  // card columns layout.

  &__columns {
    display: grid;
    grid-template-columns: 100%;

    @media (min-width: $wc-card-breakpoint) {
      grid-template-columns: 1fr 1fr;
    }

    &--narrow-start {
      grid-template-columns: 0.25fr 0.75fr;
    }

    &--narrow-end {
      grid-template-columns: 0.75fr 0.25fr;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-self: stretch;
    align-self: stretch;
  }

  // card primary action.

  &__primary-action {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: inherit;
    text-decoration: none;
    outline: none;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    &:last-child {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    @include wc-interaction-state;
  }

  // card actions.

  &__actions {
    display: flex;
    flex-direction: row;
    box-sizing: inherit;
    min-height: $wc-card-action-height;
    padding: $wc-card-action-padding;
  }

  // card action buttons blocks.

  &__action-buttons,
  &__action-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: inherit;
  }

  &__action-icons {
    flex: 1;
    justify-content: flex-end;
  }

  &__action-buttons + &__action-icons {
    margin-left: $wc-card-action-block-margin;
  }

  // card action buttons block buttons.

  &__action {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__action--button {
    margin-right: $wc-card-action-block-button-margin;
    padding-left: $wc-card-action-block-button-padding;
    padding-right: $wc-card-action-block-button-padding;

    &:last-child {
      margin-right: 0px;
    }
  }

  &__action--icon {
    margin-top: $wc-card-action-block-icon-offset;
    margin-bottom: $wc-card-action-block-icon-offset;
  }

  // card media.

  &__media {
    box-sizing: inherit;
    max-height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    &:last-child {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  
    &::before {
      display: block;
      content: "";
    }

    &--1-1 {
      @include wc-aspect-ratio(1, 1);
    }

    &--1-2 {
      @include wc-aspect-ratio(1, 2);
    }

    &--16-9 {
      @include wc-aspect-ratio(16, 9);
    }
  }

  // card primary text.

  &__primary {
    padding: $wc-card-primary-text-padding;
  }

  &__title,
  &__subtitle {
    margin: 0px;
    font-family: $wc-card-primary-text-font-family;
    text-decoration: inherit;
  }

  &__title {
    font-size: $wc-card-primary-text-title-font-size;
    font-weight: 500;
    letter-spacing: $wc-card-primary-text-title-letter-spacing;
    line-height: $wc-card-primary-text-title-line-height;
  }

  &__subtitle {
    color: rgba($wc-card-text-color, .56);
    font-size: $wc-card-primary-text-subtitle-font-size;
    font-weight: 500;
    letter-spacing: $wc-card-primary-text-subtitle-letter-spacing;
    line-height: $wc-card-primary-text-subtitle-line-height;
  }

  // card secondary text.

  &__secondary {
    flex: 1;
    padding-left: $wc-card-secondary-text-padding-x;
    padding-right: $wc-card-secondary-text-padding-x;
    color: rgba($wc-card-text-color, .84);
    font-size: $wc-card-secondary-text-font-size;
    font-family: inherit;
    font-weight: 400;
    letter-spacing: $wc-card-secondary-text-letter-spacing;
    line-height: $wc-card-secondary-text-line-height;
    text-decoration: inherit;
    overflow-x: hidden;

    &:not(&--scrollable) {
      overflow-y: hidden;
    }

    &:not(&--flush-top) {
      padding-top: $wc-card-secondary-text-padding-y;
    }

    &:not(&--flush-bottom) {
      padding-bottom: $wc-card-secondary-text-padding-y;
    }

    &--centered {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &--scrollable {
      max-height: 100%;
      padding-top: $wc-card-secondary-text-padding-y / 2;
      padding-bottom: $wc-card-secondary-text-padding-y / 2;
      overflow-y: auto;
      border-top: 1px solid rgba($wc-card-text-color, .04);
      border-bottom: 1px solid rgba($wc-card-text-color, .04);
    }
  }

  &__secondary--flush-top,
  &__primary + &__secondary {
    padding-top: 0px;
  }

  &__secondary--flush-bottom {
    padding-bottom: 0px;
  }
}
