//
// Web Components.
// Ripple.
// -------------------

@import "./keyframes";

// Ripple Host Element.
// -------------------

[data-ripple] {
  position: relative;
}

// Ripple Container.
// -------------------

.ripple {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
  pointer-events: none;

  &::before {
    position: absolute;
    top: var(--ripple-top, 0px);
    left: var(--ripple-left, 0px);
    z-index: 1;
    height: var(--ripple-size, 16px);
    width: var(--ripple-size, 16px);
    margin-top: var(--ripple-offset, -8px);
    margin-left: var(--ripple-offset, -8px);
    background-color: currentColor;
    border-radius: 100%;
    pointer-events: none;
    content: "";
    opacity: 0;
    transform: scale(0);
    transform-origin: 50% 50% 0px;
    will-change: opacity, transform;
  }

  &--animate-in::before {
    animation:
      ripple-animation-in
      $wc-ripple-animation-in-duration
      $wc-ripple-animation-in-timing-function
      forwards
    ;
  }

  &--animate-out::before {
    animation:
      ripple-animation-out
      $wc-ripple-animation-out-duration
      $wc-ripple-animation-out-timing-function
      forwards
    ;
  }
}
