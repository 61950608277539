//
// Web Components.
// Particle Keyframes.
// -------------------

@import "./variables";

@keyframes wc-particle-animation {
  0% {
    opacity: 0;
    transform: translate(0px, 0px);
  }
  10%, 50% {
    opacity: $wc-particle-opacity;
  }
  100% {
    opacity: 0;
    transform: translate(var(--particle-translate-x, 0px), var(--particle-translate-y, -120px));
  }
}
