//
// Web Components.
// Mixins.
// -------------------

@mixin wc-aspect-ratio($x, $y) {
  &::before {
    padding-top: percentage($y / $x);
  }
}

@mixin wc-interaction-state() {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    content: "";
    background-color: currentColor;
    border-radius: inherit;
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    transition:
      opacity 180ms cubic-bezier(.4, 0, .2, 1)
    ;
  }

  &:hover::after {
    opacity: .04;
  }

  &:focus::after {
    opacity: .12;
  }

  &:active::after {
    opacity: .16;
  }
}
