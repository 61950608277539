//
// Web Components.
// Carousel.
// -------------------

// import theme components.

@import "./variables";
@import "./keyframes";

// Carousel Style.
// -------------------

.carousel {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 100%;

  @media (max-width: $wc-carousel-breakpoint - 1) {
    &:not(&--no-margin) {
      margin-left: -.5rem;
      margin-right: -.5rem;
    }

    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  @media (min-width: $wc-carousel-breakpoint) {
    &:not(&--no-margin) {
      margin-left: -1rem;
      margin-right: -1rem;
    }

    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &:focus {
    outline-offset: .25rem;
  }

  &__wrapper {
    position: relative;
    box-sizing: inherit;
    margin-left: auto;
    margin-right: auto;
    width: var(--carousel-width, 360px);
    max-width: 100%;
    overflow: hidden;

    &::before {
      display: block;
      height: var(--carousel-height, 240px);
      content: "";
    }
  }

  &__item {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: inherit;
    width: 100%;
    height: 100%;
    user-select: none;
    transform: translateX(100%);

    @media (max-width: $wc-carousel-breakpoint - 1) {
      padding: 1rem;
    }

    @media (min-width: $wc-carousel-breakpoint) {
      padding: 1.25rem;
    }

    &[aria-selected="true"] {
      transform: translateX(0%);
    }

    &--to-left {
      animation:
        carousel-animation-to-left
        $wc-carousel-animation-duration
        $wc-carousel-animation-function forwards
      ;
    }

    &--to-right {
      animation:
        carousel-animation-to-right
        $wc-carousel-animation-duration
        $wc-carousel-animation-function forwards
      ;
    }

    &--from-left {
      animation:
        carousel-animation-from-left
        $wc-carousel-animation-duration
        $wc-carousel-animation-function forwards
      ;
    }

    &--from-right {
      animation:
        carousel-animation-from-right
        $wc-carousel-animation-duration
        $wc-carousel-animation-function forwards
      ;
    }
  }

  @media (max-width: $wc-carousel-breakpoint - 1) {
    [data-carousel-next],
    [data-carousel-prev] {
      margin: .5rem;
    }
  }

  @media (min-width: $wc-carousel-breakpoint) {
    [data-carousel-next],
    [data-carousel-prev] {
      margin: 1rem;
    }
  }

  [data-carousel-next] {
    order: 99;
  }

  [data-carousel-prev] {
    order: -1;
  }
}
