//
// Web Components.
// Grid Mixins.
// -------------------

@import "./variables";

//
// @private
//

@mixin wc-create-breakpoint-cells_(
  $breakpoint: "xs",
  $count: 1
) {
  @if ($count <= 0) {
    @error "Error: Cell '$count' must have at least a value of 1!";
  }

  @for $i from 1 through $count {
    &--#{$breakpoint}-#{$i} {
      grid-column: span $i;
    }
  }
}

@mixin wc-create-breakpoint-row-cells_(
  $breakpoint: "xs",
  $count: 1
) {
  @if ($count <= 0) {
    @error "Error: Cell '$count' must have at least a value of 1!";
  }

  @for $i from 1 through $count {
    &--#{$breakpoint}-rowspan-#{$i} {
      grid-row: span $i;
    }
  }
}

@mixin wc-create-order-cells_(
  $breakpoint: "xs",
  $count: 1
) {
  @if ($count <= 0) {
    @error "Error: Cell '$count' must have at least a value of 1!";
  }

  @for $i from 1 through $count {
    &--#{$breakpoint}-order-#{$i} {
      order: $i - 2;
    }
  }
}

//
// @public
//

@mixin wc-create-grid-widths(
  $breakpoints: false,
  $widths: false
) {
  @if ($breakpoints == false) {
    @error "Error: Grid '$breakpoints' has not been defined!";
  }
  @if ($widths == false) {
    @error "Error: Grid '$widths' has not been defined!";
  }

  @each $breakpoint, $width in $widths {
    @if $breakpoint == "xs" {
      &:not(&--offset):not(&--wide) { width: 100%; }
    } @else {
      @media (min-width: map-get($breakpoints, $breakpoint)) {
        &:not(&--offset):not(&--wide) {
          width: $width;
        }
      }
    }
  }
}

@mixin wc-create-responsive-cells(
  $breakpoints: false,
  $count: 1
) {
  @if ($breakpoints == false) {
    @error "Error: Cell '$breakpoints' has not been defined!";
  }
  @if ($count <= 0) {
    @error "Error: Cell '$count' must have at least a value of 1!";
  }

  @each $breakpoint, $mq in $breakpoints {
    @if $mq == 0 {
      @include wc-create-breakpoint-cells_($breakpoint, $count);
      @include wc-create-breakpoint-row-cells_($breakpoint, $count);
      @include wc-create-order-cells_($breakpoint, $count);
    } @else {
      @media (min-width: $mq) {
        @include wc-create-breakpoint-cells_($breakpoint, $count);
        @include wc-create-breakpoint-row-cells_($breakpoint, $count);
        @include wc-create-order-cells_($breakpoint, $count);
      }
    }
  }
}
