//
// Web Components.
// Particle.
// -------------------

@import "./keyframes";

// Particle Container.
// -------------------

[data-particles] {
  position: relative;

  particle {
    position: absolute;
  }
}

// Particle.
// -------------------

particle {
  position: fixed;
  top: var(--particle-top, 0px);
  left: var(--particle-left, 0px);
  z-index: 10;
  width: var(--particle-size, 10px);
  height: var(--particle-size, 10px);
  background-color: hsl(var(--particle-hue-rotation, 0), 95%, 45%);
  border-radius: 50%;
  pointer-events: none;
  opacity: 0;
  transform-origin: 50% 50% 0px;
  will-change: opacity, transform;

  &.is-animating {
    animation-name: wc-particle-animation;
    animation-delay: var(--particle-animation-delay, 120ms);
    animation-duration: var(--particle-animation-duration, 480ms);
    animation-fill-mode: forwards;
    animation-timing-function: $wc-particle-timing-function;
  }
}
