//
// Web Components.
// Ripple Keyframes.
// -------------------

@import "./variables";

// Ripple Animation In.
// -------------------

@keyframes ripple-animation-in {
  from {
    opacity: $wc-ripple-animation-opacity;
    transform: scale(1);
  }
  to {
    opacity: $wc-ripple-animation-opacity;
    transform: scale(var(--ripple-scale, 1));
  }
}

// Ripple Animation Out.
// -------------------

@keyframes ripple-animation-out {
  from {
    opacity: $wc-ripple-animation-opacity;
    transform: scale(var(--ripple-scale, 1));
  }
  to {
    opacity: 0;
    transform: scale(var(--ripple-scale, 1));
  }
}
