//
// Web Components.
// Chip.
// -------------------

@import "./variables";

// Chip Style.
// -------------------

.chip {
  position: relative;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  margin: $wc-chip-margin;
  height: $wc-chip-height;
  color: $wc-chip-text-color;
  font-family: inherit;
  font-size: $wc-chip-font-size;
  font-weight: 400;
  letter-spacing: .0175em;
  line-height: $wc-chip-line-height;
  text-transform: inherit;
  background-color: $wc-chip-bg-color;
  border-radius: $wc-chip-border-radius;
  cursor: pointer;
  // overflow: hidden;
  user-select: none;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    outline: 1px dotted currentColor;
    outline-offset: 2px;
  }

  @include wc-interaction-state;

  &:not(&--outlined) {
    padding: $wc-chip-padding-x $wc-chip-padding-y;
  }

  &--outlined {
    padding: $wc-chip-outlined-padding-x $wc-chip-outlined-padding-y;
    border: $wc-chip-outlined-border-width solid $wc-chip-outlined-border-color;
  }

  // chip text.
  // -------------------

  &__text {
    display: inline-flex;
    white-space: nowrap;
    pointer-events: none;
  }

  // chip icon.
  // -------------------

  &__icon {
    box-sizing: border-box;
    width: $wc-chip-icon-height;
    height: $wc-chip-icon-height;
    vertical-align: middle;
    color: $wc-chip-icon-color;
    font-size: $wc-chip-icon-height;
    border-radius: 50%;
    fill: $wc-chip-icon-color;
    pointer-events: none;

    &--leading,
    &:not(&--trailing) {
      order: -1;
      margin-left: -$wc-chip-icon-margin;
      margin-right: $wc-chip-icon-margin;
    }

    &--trailing {
      order: 99;
      margin-left: $wc-chip-icon-margin;
      margin-right: -$wc-chip-icon-margin;
    }
  }

  // chip image.
  // -------------------

  &__image {
    box-sizing: border-box;
    margin-top: $wc-chip-image-offset-x;
    margin-bottom: $wc-chip-image-offset-x;
    padding: $wc-chip-image-padding;
    width: $wc-chip-image-size;
    height: $wc-chip-image-size;
    color: $wc-chip-image-text-color;
    font-size: .625rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    background-color: $wc-chip-image-bg-color;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    border-radius: 50%;
    // overflow: hidden;
    pointer-events: none;

    &--leading,
    &:not(&--trailing) {
      order: -1;
      margin-left: $wc-chip-image-offset-y;
      margin-right: $wc-chip-image-margin;
    }

    &--trailing {
      order: 99;
      margin-left: $wc-chip-image-margin;
      margin-right: $wc-chip-image-offset-y;
    }
  }
}

// Chip Set.
// -------------------

.chip-set {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: $wc-chip-set-padding;

  &--center {
    justify-content: center;
  }
}
