//
// Web Components.
// Base Styles.
// -------------------

@import "../web-components/variables";
@import "../web-components/functions";
@import "../web-components/mixins";

html {
  box-sizing: border-box;
  height: 100%;
  font-size: 100%;
  scrollbar-width: thin;
  scrollbar-color: $wc-primary-color rgba(0, 0, 0, .08);
}

body {
  min-height: 100%;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: #e0e0e0;
}

::selection {
  color: #fff;
  background: $wc-secondary-color;
}

// Horizontal Rule.
// -------------------

hr, .hr {
  box-sizing: border-box;
  margin: 1.5rem 0px;
  width: 100%;
  height: .0625rem;
  border: none;
  background-size: cover;

  &.hr--primary {
    background-image: linear-gradient(
      to left,
      rgba($wc-primary-color, 0) 10%,
      rgba($wc-primary-color, .92) 50%,
      rgba($wc-primary-color, 0) 90%
    );
  }

  &.hr--secondary {
    background-image: linear-gradient(
      to left,
      rgba($wc-secondary-color, 0) 10%,
      rgba($wc-secondary-color, .92) 50%,
      rgba($wc-secondary-color, 0) 90%
    );
  }

  &.hr--tertiary {
    background-image: linear-gradient(
      to left,
      rgba($wc-tertiary-color, 0) 10%,
      rgba($wc-tertiary-color, .92) 50%,
      rgba($wc-tertiary-color, 0) 90%
    );
  }

  &.hr--quaternary {
    background-image: linear-gradient(
      to left,
      rgba($wc-quaternary-color, 0) 10%,
      rgba($wc-quaternary-color, .92) 50%,
      rgba($wc-quaternary-color, 0) 90%
    );
  }

  &.hr--dark {
    background-image: linear-gradient(
      to left,
      rgba(black, 0) 10%,
      rgba(black, .56) 50%,
      rgba(black, 0) 90%
    );
  }

  &.hr--light {
    background-image: linear-gradient(
      to left,
      rgba(white, 0) 10%,
      rgba(white, .58) 50%,
      rgba(white, 0) 90%
    );
  }
}

// Image.
// -------------------

img {
  box-sizing: border-box;
  margin: 0px;
  max-width: 100%;
  height: auto;

  &:not(.img--clear) {
    background-color: #bdbdbd;
  }

  &:not(.img--inline) {
    display: block;
  }

  &.img--inline {
    display: inline-block;
  }

  &:not(.img--border) {
    border: none;
  }

  &.img--border {
    border: .5rem solid currentColor;

    @each $color, $value in $wc-color-map {
      &.img--#{if($color == "", "dark", $color)} {
        border-color: $value;
      }
    }
  }

  &.img--circle {
    border-radius: 50%;
  }

  &.img--rounded {
    border-radius: $wc-border-radius;
  }

  &.img--cover {
    object-fit: cover;
    object-position: 50% 50%;
  }
}

// Image Banner.
// -------------------

.img-banner {
  position: relative;
  display: block;
  max-width: 100%;
  overflow: hidden;
  border-radius: .5rem;

  &--no-radius {
    border-radius: 0;
  }

  &::after {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    // width: 22rem;
    // height: 5rem;
    padding-bottom: .5rem;
    // font-size: 1rem;
    font-weight: 500;
    letter-spacing: .035em;
    line-height: 1.25rem;
    content: attr(data-text);
    // transform: rotate(-45deg) translate(-27.5%, -135%);

    @media (max-width: map-get($wc-breakpoints, "md") - 1) {
      width: 12rem;
      height: 5rem;
      font-size: .875rem;
      transform: rotate(-45deg) translate(-20%, -80%);
    }

    @media (min-width: map-get($wc-breakpoints, "md")) {
      width: 22rem;
      height: 6rem;
      font-size: 1.125rem;
      transform: rotate(-45deg) translate(-26.25%, -125%);
    }
  }

  @each $name, $color in $wc-img-banner-bg-color-map {
    &--#{$name}::after {
      color: wc-contrast-color($color);
      background-color: $color;
    }
  }
}

// Links.
// -------------------

a,
.link {
  color: $wc-secondary-color;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: darken($wc-secondary-color, 20%);
    text-decoration: underline;
  }
}
