//
// Web Components.
// Textfield.
// -------------------

@import "./variables";

// Textfield Style.
// -------------------

.textfield {
  position: relative;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 1.25rem 0px;
  padding: 0px;
  font-size: $wc-textfield-font-size;
  font-family: inherit;
  letter-spacing: $wc-textfield-letter-spacing;
  line-height: $wc-textfield-line-height;
  border: none;
  border-radius: $wc-textfield-border-radius;
  caret-color: $wc-textfield-base-color;

  &:not(&--block) {
    display: inline-flex;
  }

  &--block {
    display: flex;
    width: 100%;
  }

  &:not(&--light) {
    color: rgba($wc-textfield-base-color, .84);
  }

  // textfield input.

  &__input {
    display: flex;
    box-sizing: inherit;
    padding: 1.5rem 1rem .5rem 1rem;
    width: 100%;
    color: currentColor;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    letter-spacing: inherit;
    line-height: inherit;
    background-color: transparent;
    border: none;
    border-radius: inherit;

    textarea#{&} {
      resize: vertical;
    }
  
    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:disabled {
      opacity: .56;
      pointer-events: none;
    }

    &::placeholder {
      opacity: 0;
    }
  }

  &--light &__input {
    caret-color: $wc-textfield-light-color;
  }

  &--primary &__input {
    caret-color: $wc-textfield-primary-color;
  }

  &--secondary &__input {
    caret-color: $wc-textfield-secondary-color;
  }

  &--leading-icon &__input {
    padding-left: 3.25rem;
  }

  &--leading-icon &__input {
    padding-right: 3.25rem;
  }

  // textfield label.

  &__label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    box-sizing: inherit;
    font-weight: 400;
    pointer-events: none;
    transform: scale(1) translateY(0%);
    transform-origin: top left;
    transition:
      color
      $wc-textfield-transition-duration
      $wc-textfield-transition-timing-function,
      font-weight
      $wc-textfield-transition-duration
      $wc-textfield-transition-timing-function,
      transform
      $wc-textfield-transition-duration
      $wc-textfield-transition-timing-function
    ;
  }

  &__input:not(:disabled) ~ &__label {
    color: rgba($wc-textfield-base-color, .64);
  }

  &--light &__input:not(:disabled) ~ &__label {
    color: rgba($wc-textfield-light-color, .64);
  }

  &--is-dirty &__label,
  &--has-focus &__label,
  &__input:focus ~ &__label,
  &__input:not(:placeholder-shown) ~ &__label {
    font-weight: 500;
    transform: scale(.75) translateY(-50%);
    transition-duration:
      $wc-textfield-transition-duration,
      $wc-textfield-transition-duration,
      $wc-textfield-transition-duration / 2
    ;
  }

  &--leading-icon &__label {
    left: 3.25rem;
  }

  &--trailing-icon &__label {
    right: 3.25rem;
  }

  // theme.

  &__input:not(:disabled):focus ~ &__label {
    color: $wc-textfield-base-color;
  }

  &--light &__input:not(:disabled):focus ~ &__label {
    color: $wc-textfield-light-color;
  }

  &--primary &__input:not(:disabled):focus ~ &__label {
    color: $wc-textfield-primary-color;
  }

  &--secondary &__input:not(:disabled):focus ~ &__label {
    color: $wc-textfield-secondary-color;
  }

  // disabled.

  &__input:disabled ~ &__label,
  &__input:disabled:focus ~ &__label,
  &__input:disabled:not(:placeholder-shown) ~ &__label {
    opacity: .74;
  }

  // textfield icon.

  &__icon {
    position: absolute;
    top: $wc-textfield-icon-offset-y;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $wc-textfield-icon-size;
    height: $wc-textfield-icon-size;
    color: currentColor;
    font-size: $wc-textfield-icon-size;
    pointer-events: none;
    opacity: .56;
  }

  &--leading-icon &__icon {
    order: -1;
    left: $wc-textfield-icon-offset-x;
  }

  &--trailing-icon &__icon {
    order: 999;
    right: $wc-textfield-icon-offset-x;
  }

  // shared style.
  // textfield background/ripple.

  &__background,
  &__ripple {
    position: absolute;
    display: block;
    box-sizing: inherit;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    pointer-events: none;
  }

  // textfield background.

  &__background {
    background-color: currentColor;
    opacity: 0;
    transition:
      opacity
      $wc-textfield-transition-duration
      $wc-textfield-transition-timing-function
    ;
  }

  // interaction.

  &__input:not(:disabled):hover ~ &__background {
    opacity: .04;
  }

  &--has-focus &__input:not(:disabled) ~ &__background,
  &__input:not(:disabled):focus ~ &__background {
    opacity: .08;
  }

  // theme.

  &--light &__background {
    background-color: $wc-textfield-light-color;
  }

  &--primary &__background {
    background-color: $wc-textfield-primary-color;
  }

  &--secondary &__background {
    background-color: $wc-textfield-secondary-color;
  }

  // textfield ripple.

  &__ripple::before,
  &__ripple::after {
    position: absolute;
    display: block;         
    box-sizing: inherit;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border-style: solid;
    content: "";
    transition:
      opacity
      $wc-textfield-transition-duration
      $wc-textfield-transition-timing-function
    ;
  }

  &__ripple::before {
    border-width: .065rem;
    opacity: .5;
  }

  &__input:not(:disabled):hover ~ &__ripple::before {
    opacity: .8;
  }

  &__ripple::after {
    border-width: .125rem;
    opacity: 0;
  }

  &--has-focus &__input:not(:disabled):focus ~ &__ripple::after,
  &__input:not(:disabled):focus ~ &__ripple::after {
    opacity: 1;
  }

  // interaction.

  &__ripple::before {
    border-color: $wc-textfield-base-color;
  }

  &--light &__ripple::before {
    border-color: $wc-textfield-light-color;
  }

  // theme.

  &__ripple::after {
    border-color: $wc-textfield-base-color;
  }

  &--light &__ripple::after {
    border-color: $wc-textfield-light-color;
  }

  &--primary &__ripple::after {
    border-color: $wc-textfield-primary-color;
  }

  &--secondary &__ripple::after {
    border-color: $wc-textfield-secondary-color;
  }
}
