//
// Web Components.
// Sidebar.
// -------------------

.sidebar {
  @media (max-width: 759px) {
    > * + * {
      margin-top: 8px;
    }
  }

  @media (min-width: 760px) {
    > * + * {
      margin-top: 16px;
    }
  }

  &__nav {
    margin: 0px;
    padding: 0px 0px 16px;
    list-style: none;
  }

  &__nav-item {
    padding: 0px;

    a {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 8px 16px;
      text-decoration: none;
    }
  }

  &__nav-item-text {
    margin-right: 8px;
    font-size: 1.125rem;
    line-height: 1.25;
  }

  &__nav-item-desc {
    display: block;
    max-width: 100%;
    color: rgba(#000, 0.57);
    font-size: 0.875rem;
    line-height: 1.25;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__nav-item-count {
    margin-left: auto;
    font-size: 0.875rem;
    font-weight: 500;
  }
}