//
// Web Components.
// Responsive Video.
// -------------------

// import theme components.

@import "../web-components/variables";
@import "../web-components/functions";
@import "../web-components/mixins";

.responsive-video {
  position: relative;
  display: block;
  box-sizing: inherit;

  &::before {
    display: block;
    content: "";
  }

  &--default {
    @include wc-aspect-ratio(4, 3);
  }

  &--wide {
    @include wc-aspect-ratio(16, 9);
  }

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}