//
// Web Components.
// Appbar.
// -------------------

@import "./variables";
@import "../elevation/mixins";

// Appbar Style.
// -------------------

.appbar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 5;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  font-size: $wc-appbar-font-size;
  letter-spacing: $wc-appbar-letter-spacing;
  line-height: $wc-appbar-line-height;
  transition:
    box-shadow
    $wc-appbar-transition-duration
    $wc-appbar-transition-timing-function
  ;

  @media (max-width: $wc-appbar-breakpoint - 1) {
    min-height: $wc-appbar-height-sm;
    padding-left: $wc-appbar-padding-sm;
    padding-right: $wc-appbar-padding-sm;
  }

  @media (min-width: $wc-appbar-breakpoint) {
    min-height: $wc-appbar-height-lg;
    padding-left: $wc-appbar-padding-lg;
    padding-right: $wc-appbar-padding-lg;
  }

  &:not(&--has-shadow) {
    @include wc-elevation(0);
  }

  &--has-shadow {
    @include wc-elevation(4);
  }

  // Appbar Row.
  // -------------------

  &__row {
    display: flex;
    flex: 1;
    box-sizing: inherit;

    @media (max-width: $wc-appbar-breakpoint - 1) {
      height: $wc-appbar-height-sm;
      padding-top: $wc-appbar-padding-sm;
      padding-bottom: $wc-appbar-padding-sm;
    }

    @media (min-width: $wc-appbar-breakpoint) {
      height: $wc-appbar-height-lg;
      padding-top: $wc-appbar-padding-lg;
      padding-bottom: $wc-appbar-padding-lg;
    }
  }

  // Appbar Section.
  // -------------------

  &__section {
    display: inline-flex;
    align-items: center;
    flex: 1;
    box-sizing: inherit;

    &--start {
      justify-content: flex-start;
      order: -1;
    }

    &--end {
      justify-content: flex-end;
      order: 1;
    }
  }

  // Shared Style.
  // -------------------

  &__title,
  &__link {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: inherit;
    font-family: inherit;

    &,
    &:hover,
    &:focus,
    &:active {
      color: currentColor;
      text-decoration: none;
    }

    &:focus {
      outline: 1px dotted currentColor;
      outline-offset: -.125rem;
    }

    @include wc-interaction-state;
  }

  // Appbar Title.
  // -------------------

  &__title {
    padding: $wc-appbar-title-padding;
    font-weight: 500;
  }

  // Appbar Links.
  // -------------------

  &__link {
    padding: $wc-appbar-link-padding;
    font-weight: 400;
  }

  // Appbar Buttons.
  // -------------------

  &__button:not(:disabled),
  &__button:not(&--disabled) {
    color: currentColor;
  }

  // Appbar Theme.
  // -------------------

  &:not(&--primary):not(&--secondary) {
    color: wc-contrast-color($wc-appbar-primary-color);
    background-color: $wc-appbar-base-color;
  }

  &--primary {
    color: wc-contrast-color($wc-appbar-primary-color);
    background-color: $wc-appbar-primary-color;
  }

  &--light#{&}--primary {
    color: $wc-appbar-primary-color;
  }

  &--secondary {
    color: wc-contrast-color($wc-appbar-secondary-color);
    background-color: $wc-appbar-secondary-color;
  }

  &--light#{&}--secondary {
    color: $wc-appbar-secondary-color;
  }

  &--tertiary {
    color: wc-contrast-color($wc-appbar-tertiary-color);
    background-color: $wc-appbar-tertiary-color;
  }

  &--light#{&}--tertiary {
    color: $wc-appbar-tertiary-color;
  }

  &--light:not(&--primary):not(&--secondary) {
    color: wc-contrast-color($wc-appbar-light-color);
  }

  &--light {
    background-color: $wc-appbar-light-color;
  }
}

// Appbar Offset.
// -------------------

.appbar-offset {
  @media (max-width: $wc-appbar-breakpoint - 1) {
    padding-top: $wc-appbar-height-sm;
  }

  @media (min-width: $wc-appbar-breakpoint) {
    padding-top: $wc-appbar-height-lg;
  }
}
