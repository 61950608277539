//
// Web Components.
// Layout.
// -------------------

@import "../elevation/mixins";
@import "./variables";

.pagination {
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: $wc-layout-breakpoint - 1) {
    margin-top: 1rem;
  }

  @media (min-width: $wc-layout-breakpoint) {
    margin-top: 1.5rem;
  }

  .previous {
    margin-right: auto;
  }

  .next {
    margin-left: auto;
  }

  // Article pagination pager.

  .pager {
    display: flex;
    flex-direction: column;
    text-decoration: none;

    &__title {
      color: $wc-secondary-color;
      font-size: 1.125rem;
      font-weight: 500;
    }

    &__direction {
      color: rgba(#000, 0.36);
      font-size: 0.875rem;
    }

    &__icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
    }
  }

  .previous .pager {
    text-align: left;

    &__icon {
      margin-right: 1rem;
    }
  }

  .next .pager {
    text-align: right;

    &__icon {
      margin-left: 1rem;
    }
  }
}
