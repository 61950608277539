//
// Web Components.
// Carousel Keyframes Animation.
// -------------------

@keyframes carousel-animation-from-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes carousel-animation-to-left {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes carousel-animation-from-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes carousel-animation-to-right {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
