//
// Web Components.
// Utils.
// -------------------

.screen-reader {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  word-wrap: normal;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

// Positional helpers.

.float-left,
.pull-left {
  float: left;
}

.float-right,
.pull-right {
  float: right;
}

.margin-top-24 {
  margin-top: 1.5rem;
}

.margin-top-16 {
  margin-top: 1rem;
}

.margin-top-8 {
  margin-top: .5rem;
}

.margin-bottom-24 {
  margin-bottom: 1.5rem;
}

.margin-bottom-16 {
  margin-bottom: 1rem;
}

.margin-bottom-8 {
  margin-bottom: .5rem;
}

.margin-left-24 {
  margin-left: 1.5rem;
}

.margin-left-16 {
  margin-left: 1rem;
}

.margin-left-8 {
  margin-left: .5rem;
}

.margin-right-24 {
  margin-right: 1.5rem;
}

.margin-right-16 {
  margin-right: 1rem;
}

.margin-right-8 {
  margin-right: .5rem;
}

// Clearfix.

.clearfix::after {
  display: table;
  content: "";
  clear: both;
}
