//
// Web Components.
// Grid.
// -------------------

@import "./mixins";

// Grid Style.
// -------------------

.#{$wc-grid-name} {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat($wc-grid-cell-count, 1fr);
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  @include wc-create-grid-widths($wc-grid-breakpoints, $wc-grid-widths);

  @media (max-width: $wc-grid-gutter-breakpoint - 1) {
    grid-gap: $wc-grid-gutter * 2;

    &:not(&--no-margin) {
      margin-bottom: $wc-grid-gutter;
    }

    &--offset {
      margin-left: -$wc-grid-gutter;
      margin-right: -$wc-grid-gutter;
    }
  }

  @media (min-width: $wc-grid-gutter-breakpoint) {
    grid-gap: $wc-grid-gutter * 3;

    &:not(&--no-margin) {
      margin-bottom: $wc-grid-gutter * 2;
    }

    &--offset {
      margin-left: -$wc-grid-gutter * 2;
      margin-right: -$wc-grid-gutter * 2;
    }
  }

  &--dense {
    grid-auto-flow: row dense;
  }

  &--wide:not(&--offset) {
    width: 100%;
  }

  &--offset {
    flex-shrink: 0;
    width: auto;
  }
}

// Cell.
// -------------------

.#{$wc-grid-cell-name} {
  box-sizing: border-box;
  grid-column: span $wc-grid-cell-count;
  max-width: 100%;

  &--flex {
    display: flex;
    flex-direction: column;
  }

  &--center {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: $wc-grid-gutter-breakpoint - 1) {
    .#{$wc-grid-name}--offset & {
      padding: $wc-grid-gutter;
    }
  }

  @media (min-width: $wc-grid-gutter-breakpoint) {
    .#{$wc-grid-name}--offset & {
      padding: $wc-grid-gutter * 2;
    }
  }

  @include wc-create-responsive-cells($wc-grid-breakpoints, $wc-grid-cell-count);

  & > .#{$wc-grid-name} {
    width: 100%;
    margin-bottom: 0px;
  }
}
