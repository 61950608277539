//
// Web Components.
// Elevation.
// -------------------

@import "./mixins";

@for $i from 0 to $wc-elevation-max-classes-count {
  .elevation--#{$i} {
    @include wc-elevation($i);
  }
}
