//
// Web Components.
// Typography.
// -------------------

@import "../web-components/variables";
@import "../web-components/functions";
@import "./variables";

// Headings.
// -------------------

h1, h2, h3,
h4, h5, h6,
.h1, .h2, .h3,
.h4, .h5, .h6 {
  margin-top: 0px;
  font-family: $wc-heading-font-family;
  text-decoration: inherit;
  text-transform: inherit;
}

h1, h2,
.h1, .h2 {
  margin-bottom: 2rem;
}

h1, h2, h3,
.h1, .h2, .h3 {
  font-weight: 300;
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
  margin-bottom: 1.5rem;
}

h4, h5,
.h4, .h5 {
  font-weight: 400;
}

h1, .h1 {
  font-size: 2.75rem;
  letter-spacing: wc-letter-spacing(-1.25, 2.75);
  line-height: 2.75rem;
}

h2, .h2 {
  font-size: 2.375rem;
  letter-spacing: wc-letter-spacing(-0.5, 2.375);
  line-height: 2.375rem;
}

h3, .h3 {
  font-size: 2rem;
  letter-spacing: wc-letter-spacing(0, 2);
  line-height: 2rem;
}

h4, .h4 {
  font-size: 1.625rem;
  letter-spacing: wc-letter-spacing(0.25, 1.625);
  line-height: 1.675rem;
}

h5, .h5 {
  font-size: 1.375rem;
  letter-spacing: wc-letter-spacing(0, 1.375);
  line-height: 1.375rem;
}

h6, .h6 {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: wc-letter-spacing(0.15, 2.25);
  line-height: 1.25rem;
}

// Lists.
// -------------------

ol.list--unstyled,
ul.list--unstyled {
  display: block;
  margin: 0px;
  padding: 0px;
  width: 100%;
  list-style-type: none;

  li.list--heading {
    font-weight: 700;
  }
}

// Paragraphs/Quotes.
// -------------------

p {
  margin-top: 0px;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  &.p--block-center {
    margin-left: auto;
    margin-right: auto;
  }

  &.p--max-chars {
    max-width: 65ch;
  }
}

q,
blockquote {
  quotes: "\0022" "\0022" "\2027" "\2027";

  &::before {
    margin-right: .125rem;
    content: open-quote;
  }

  &::after {
    margin-left: .125rem;
    content: close-quote;
  }
}

.q--indent {
  margin-left: -.45em;
}

blockquote,
.callout-block {
  margin: 0rem 0rem 1rem 0rem;
  padding: 1rem 1.5rem;
  font-family: $wc-blockquote-font-family;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: wc-letter-spacing(0.15, 2.25);
  line-height: 1.25;
  background-color: rgba(black, .06);
  border: 0 solid;
  border-top-width: .25rem;
  border-bottom-width: .25rem;
}

blockquote {
  text-indent: -.45em;

  @supports (hanging-punctuation: first) {
    text-indent: 0;
    hanging-punctuation: first;
  }

  @each $color, $value in $wc-color-map {
    @if $color == "" {
      border-color: $value;
    } @else {
      @at-root .#{&}--#{$color} {
        border-color: $value;
      }
    }
  }
}

.callout-block {
  @each $color, $value in $wc-color-map {
    @if $color == "" {
      border-color: $value;
    } @else {
      @at-root #{&}--#{$color} {
        border-color: $value;
      }
    }
  }
}

// Text Style.
// -------------------

b,
strong,
.strong,
.text-bold {
  font-weight: 700;
}

.text-medium {
  font-weight: 500;
}

.text-normal {
  font-weight: 400;
}

.text-fine {
  font-weight: 300;
}

small,
.small,
.text-small {
  font-size: 82.5%;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-mute {
  opacity: .64;
}

.text-titlecase {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

@each $color, $value in $wc-color-map {
  .text-#{if($color == "", "dark", $color)} {
    color: $value;
  }
}

.text-anna-purple {
  color: #ac47ff;
}

.text-livvic {
  font-family: 'Livvic', sans-serif;
}

.text-poppins {
  font-family: 'Poppins', sans-serif;
}

.text-yellowtail {
  font-family: 'Yellowtail', cursive;
}

@each $size, $value in $wc-typography-text-size-styles-map {
  .text-#{$size} {
    font-size: $value !important;
  }
}
