//
// Web Components.
// Tooltip.
// -------------------

@import "./variables";

// Tooltip Style.
// -------------------

.tooltip {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-width: $wc-tooltip-max-width;
  height: $wc-tooltip-height;
  padding: $wc-tooltip-padding-y $wc-tooltip-padding-x;
  color: $wc-tootip-base-text-color;
  font-family: inherit;
  font-size: $wc-tooltip-font-size;
  font-weight: $wc-tooltip-font-weight;
  letter-spacing: $wc-tooltip-letter-spacing;
  line-height: $wc-tooltip-line-height;
  text-overflow: ellipsis;
  text-shadow: 0px 0px 1px rgba(black, .08);
  white-space: nowrap;
  background-color: $wc-tootip-base-bg-color;
  border-radius: $wc-tooltip-border-radius;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition:
    opacity 60ms ease-in 60ms,
    transform 0ms ease-in 120ms
  ;

  @media (prefers-reduced-motion: reduce) {
    transition-duration: 15ms, 0ms;
  }

  // tooltip positions.

  &--bottom {
    margin-top: $wc-tooltip-offset;
    transform: scale($wc-tooltip-initial-scale) translate(0px, -$wc-tooltip-offset);
    transform-origin: top center;
  }

  &--top {
    margin-top: -$wc-tooltip-offset;
    transform: scale($wc-tooltip-initial-scale) translate(0px, $wc-tooltip-offset);
    transform-origin: bottom center;
  }

  &--right {
    margin-left: $wc-tooltip-offset;
    transform: scale($wc-tooltip-initial-scale) translate(-$wc-tooltip-offset, 0px);
    transform-origin: center left;
  }

  &--left {
    margin-left: -$wc-tooltip-offset;
    transform: scale($wc-tooltip-initial-scale) translate($wc-tooltip-offset, 0px);
    transform-origin: center right;
  }

  // tooltip visible.

  &--is-visible {
    opacity: .95;
    transform: scale(1) translate(0px, 0px);
    transition-delay: 250ms, 250ms;
    transition-duration: 180ms, 180ms;

    @media (prefers-reduced-motion: reduce) {
      transition-duration: 25ms, 30ms;
    }
  }
}
