//
// Web Components.
// Scroll Top.
// -------------------

@import "./variables";

.scroll-top {
  position: fixed;
  z-index: 50;
  transition:
    all
    $wc-scroll-top-transition-duration
    $wc-scroll-top-transition-timing-function
  ;
  transform-origin: bottom center;

  @media (max-width: $wc-scroll-top-breakpoint - 1) {
    bottom: $wc-scroll-top-spacing / 2;
    right: $wc-scroll-top-spacing / 2;
  }

  @media (min-width: $wc-scroll-top-breakpoint) {
    bottom: $wc-scroll-top-spacing;
    left: 50%;
    margin-left: -1.25rem;
  }

  &:not(&--visible) {
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);
  }

  &--visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
  }
}
