//
// Web Components.
// Layout Presentation.
// -------------------

@import "./variables";
@import "../elevation/mixins";

// Shared Styles.
// -------------------

.banner,
.highlight {
  box-sizing: border-box;
}

.banner {
  display: flex;
  flex-direction: column;
}

// Presentation Banner.
// -------------------

.banner {
  justify-content: center;
  background-color: transparent;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  &:not(&--auto-height) {
    min-height: 560px;
    min-height: 75vh;
  }

  &:not(&--no-offset) {
    @media (max-width: $wc-layout-breakpoint - 1) {
      margin-left: -($wc-layout-banner-padding-sm);
      margin-right: -($wc-layout-banner-padding-sm);
    }
  
    @media (min-width: $wc-layout-breakpoint) {
      margin-left: -$wc-layout-banner-padding;
      margin-right: -$wc-layout-banner-padding;
    }
  }

  @media (max-width: $wc-layout-breakpoint - 1) {
    padding: ($wc-layout-banner-padding * 1.75) $wc-layout-banner-padding-sm;
  }

  @media (min-width: $wc-layout-breakpoint) {
    padding: ($wc-layout-banner-padding * 2.5) $wc-layout-banner-padding;
  }

  &--center {
    align-items: center;
  }

  @each $color, $value in $wc-layout-banner-color-map {
    &--#{$color} {
      color: wc-contrast-color($value, rgba(white, .86), rgba(black, .84));
      background-color: $value;
    }
  }
}

// Presentation Highlight.
// -------------------

.highlight {
  position: relative;
  display: grid;
  grid-gap: $wc-layout-highlight-gutter;
  gap: $wc-layout-highlight-gutter;
  grid-auto-rows: auto;

  @media (max-width: $wc-layout-breakpoint - 1) {
    grid-template-columns: $wc-layout-highlight-base-size;
    margin: $wc-layout-highlight-gutter-sm auto;
  }

  @media (min-width: $wc-layout-breakpoint) {
    grid-template-columns: ($wc-layout-highlight-base-size / 3) ($wc-layout-highlight-base-size / 1.5);
    margin: $wc-layout-highlight-gutter auto;
  }

  &:not(&--no-offset) {
    @media (max-width: $wc-layout-breakpoint - 1) {
      margin-left: -($wc-layout-highlight-gutter-sm);
      margin-right: -($wc-layout-highlight-gutter-sm);
    }
  
    @media (min-width: $wc-layout-breakpoint) {
      margin-left: -$wc-layout-highlight-gutter;
      margin-right: -$wc-layout-highlight-gutter;
    }
  }

  &__background,
  &__content {
    box-sizing: inherit;
  }

  &__background {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: inherit;
    background-color: transparent;
    background-size: 0px;

    @media (max-width: $wc-layout-breakpoint - 1) {
      padding:
        ($wc-layout-highlight-gutter-sm * 2.5)
        $wc-layout-highlight-gutter-sm
        ($wc-layout-highlight-gutter-sm * 1.5)
        $wc-layout-highlight-gutter-sm
      ;
    }

    @media (min-width: $wc-layout-breakpoint) {
      padding:
        $wc-layout-highlight-gutter
        0rem
        $wc-layout-highlight-gutter
        $wc-layout-highlight-gutter
      ;
    }

    &::before {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -1;
      content: "";
      background-image: inherit;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;

      @media (max-width: $wc-layout-breakpoint - 1) {
        bottom: -($wc-layout-highlight-gutter-sm * 3);
        right: 0px;
      }

      @media (min-width: $wc-layout-breakpoint) {
        bottom: 0px;
        right: -($wc-layout-highlight-gutter * 2.75);
        border-top-right-radius: $wc-border-radius;
        border-bottom-right-radius: $wc-border-radius;
      }
    }

    &--has-shadow::before {
      @include wc-elevation(1);
    }
  }

  @each $color, $value in $wc-layout-highlight-color-map {
    &--#{$color} &__background {
      color: wc-contrast-color($value, rgba(white, .86), rgba(black, .84));

      &::before {
        background-color: $value;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    box-sizing: inherit;
    background-color: transparent;

    @media (max-width: $wc-layout-breakpoint - 1) {
      padding-left: ($wc-layout-highlight-gutter-sm * 1.5);
      padding-right: ($wc-layout-highlight-gutter-sm * 1.5);
      text-align: center;
    }

    @media (min-width: $wc-layout-breakpoint) {
      padding-top: ($wc-layout-highlight-gutter * 1.25);
      padding-bottom: ($wc-layout-highlight-gutter * 1.25);
      padding-right: $wc-layout-highlight-gutter;
    }
  }
}
