//
// Web Components.
// Elevation Mixins.
// -------------------

@import "./variables";

@mixin wc-elevation($z-index) {
  @if type-of($z-index) != number or not unitless($z-index) {
    @error "$z-index must be a unitless number, but received '#{$z-index}'";
  }

  @if $z-index < 0 or $z-index > 24 {
    @error "$z-index must be between 0 and 24, but received '#{$z-index}'";
  }

  $umbra-z-index: map-get($wc-umbra-map, $z-index);
  $penumbra-z-index: map-get($wc-penumbra-map, $z-index);
  $ambient-z-index: map-get($wc-ambient-map, $z-index);

  $umbra-color: rgba(black, $wc-umbra-opacity);
  $penumbra-color: rgba(black, $wc-penumbra-opacity);
  $ambient-color: rgba(black, $wc-ambient-opacity);

  $box-shadow: (
    #{"#{$umbra-z-index} #{$umbra-color}"},
    #{"#{$penumbra-z-index} #{$penumbra-color}"},
    #{$ambient-z-index} $ambient-color,
  );

  box-shadow: $box-shadow;
}
