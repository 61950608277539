//
// Web Components.
// Button.
// -------------------

@import "./variables";
@import "../elevation/mixins";

// Shared Style.
// -------------------

.button,
.fab,
.icon-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  appearance: none;
  font-family: inherit;
  border: none;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;

  @include wc-interaction-state;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    outline: 1px dotted currentColor;
  }

  // Shared Icon Style.
  // -------------------

  &__icon {
    box-sizing: border-box;
    vertical-align: middle;
    color: currentColor;
    fill: currentColor;
    pointer-events: none;

    &,
    &::before {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &::before {
      width: inherit;
      height: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.button__text,
.fab__text {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  pointer-events: none;
}

// Button Style.
// -------------------

.button {
  min-height: $wc-button-size;
  font-size: $wc-button-font-size;
  font-weight: $wc-button-font-weight;
  letter-spacing: $wc-button-letter-spacing;
  line-height: $wc-button-line-height;
  border-radius: $wc-button-border-radius;

  &--block {
    display: flex;
    width: 100%;
  }

  &:not(&--outlined) {
    padding-top: $wc-button-padding;
    padding-bottom: $wc-button-padding;

    &:focus {
      outline-offset: -.25rem;
    }
  }

  &:not(&--flat):not(&--outlined):not(&--raised) {
    padding-left: $wc-button-padding;
    padding-right: $wc-button-padding;
  }

  &:not(&--flat):not(&--raised) {
    background-color: transparent;
  }

  &--flat,
  &--raised {
    padding-left: $wc-button-padding-lg;
    padding-right: $wc-button-padding-lg;
  }

  &--outlined {
    padding-top: $wc-button-padding - $wc-button-oulined-border-size;
    padding-bottom: $wc-button-padding - $wc-button-oulined-border-size;
    padding-left: $wc-button-padding-lg - $wc-button-oulined-border-size;
    padding-right: $wc-button-padding-lg - $wc-button-oulined-border-size;
    border: $wc-button-oulined-border-size solid currentColor;

    &:focus {
      outline-offset: -.375rem;
    }
  }

  // Raised Buttton.
  // -------------------

  &--raised {
    @include wc-elevation(2);

    will-change: box-shadow;
    transition:
      box-shadow
      $wc-button-transition-duration
      $wc-button-transition-timing-function
    ;

    &:hover,
    &:focus {
      @include wc-elevation(4);
    }

    &:active {
      @include wc-elevation(8);
    }

    &:disabled {
      box-shadow: none;
    }
  }

  // Button Icon.
  // -------------------

  &__icon {
    width: $wc-button-icon-size;
    height: $wc-button-icon-size;
    font-size: $wc-button-icon-size;
    line-height: $wc-button-icon-size;
  }

  &--leading-icon &__icon,
  &:not(#{&}--trailing-icon) &__icon {
    order: -1;
    margin-left: -$wc-button-icon-margin;
    margin-right: $wc-button-icon-margin * 1.5;
  }

  &--trailing-icon &__icon {
    order: 99;
    margin-left: $wc-button-icon-margin * 1.5;
    margin-right: -$wc-button-icon-margin;
  }

  // Button Secondary Text.
  // -------------------

  &__text-secondary {
    font-size: $wc-button-text-secondary-font-size;
    font-weight: $wc-button-text-secondary-font-weight;
    line-height: $wc-button-text-secondary-line-height;
    opacity: .84;
  }
}

// FAB Style.
// -------------------

.fab {
  line-height: $wc-fab-button-line-height;
  will-change: box-shadow;
  transition:
    box-shadow
    $wc-button-transition-duration
    $wc-button-transition-timing-function
  ;
  @include wc-elevation(6);

  &:hover,
  &:focus {
    @include wc-elevation(8);
  }

  &:active {
    @include wc-elevation(12);
  }

  &:disabled {
    box-shadow: none;
  }

  // Default Size.
  // -------------------

  &:not(&--mini) {
    height: $wc-fab-button-size;
    padding: $wc-fab-button-padding;
    border-radius: $wc-fab-button-border-radius;
    outline-offset: -$wc-fab-button-padding;
  }

  &:not(&--mini):not(&--extended):not(&--block) {
    width: $wc-fab-button-size;
  }

  // Mini Size.
  // -------------------

  &--mini {
    width: $wc-fab-mini-button-size;
    height: $wc-fab-mini-button-size;
    padding: $wc-fab-mini-button-padding;
    border-radius: $wc-fab-mini-border-radius;
    outline-offset: -$wc-fab-mini-button-padding;
  }

  &--mini:not(&--extended):not(&--block) {
    width: $wc-fab-mini-button-size;
  }

  &--extended {
    width: auto;
  }

  &--block {
    display: flex;
    width: 100%;
  }

  // FAB Icon.
  // -------------------

  &__icon {
    width: $wc-fab-button-icon-size;
    height: $wc-fab-button-icon-size;
    font-size: $wc-fab-button-icon-size;
    line-height: $wc-fab-button-icon-size;
  }

  // FAB Text.
  // -------------------

  &__text {
    font-weight: 500;
    letter-spacing: $wc-fab-button-letter-spacing;
  }

  &:not(&--mini) &__text {
    padding-left: $wc-fab-button-text-offset;
    padding-right: $wc-fab-button-text-offset;
  }

  &--mini &__text {
    padding-left: $wc-fab-mini-button-text-offset;
    padding-right: $wc-fab-mini-button-text-offset;
  }
}

// Icon Style.
// -------------------

.icon-button {
  width: $wc-icon-button-size;
  height: $wc-icon-button-size;
  padding: $wc-icon-button-padding;
  font-size: $wc-icon-button-icon-size;
  line-height: $wc-icon-button-line-height;
  background-color: transparent;
  border-radius: $wc-icon-button-border-radius;

  &:focus {
    outline-offset: -$wc-icon-button-padding;
  }

  // Icon Button Icon.
  // -------------------

  &__icon {
    width: $wc-icon-button-icon-size;
    height: $wc-icon-button-icon-size;
    font-size: inherit;
    line-height: $wc-icon-button-icon-size;
  }
}

// Button/FAB/Icon Theme.
// -------------------

@each $color, $value in map-merge($wc-color-map, $wc-social-media-color-palette-map) {
  @if $color == "" {
    .button:not(.button--flat):not(.button--raised):not(:disabled),
    .icon-button:not(:disabled) {
      color: $value;
    }
  } @else {
    .button--#{$color}:not(.button--flat):not(.button--raised):not(:disabled),
    .icon-button--#{$color}:not(:disabled) {
      color: $value;
    }
  }
}

@each $color, $value in map-merge($wc-color-map, $wc-social-media-color-palette-map) {
  @if $color == "" {
    .button--flat:not(:disabled),
    .button--raised:not(:disabled),
    .fab:not(:disabled) {
      color: wc-contrast-color($value, rgba(white, .86), rgba(black, .84));
      background-color: $value;
    }
  } @else {
    .button--flat.button--#{$color}:not(:disabled),
    .button--raised.button--#{$color}:not(:disabled),
    .fab.fab--#{$color}:not(:disabled) {
      color: wc-contrast-color($value, rgba(white, .86), rgba(black, .84));
      background-color: $value;
    }
  }
}

// Button/FAB/Icon Disabled.
// -------------------

.button:disabled,
.fab:disabled,
.icon-button:disabled {
  color: rgba($wc-button-disabled-color, .32);
  pointer-events: none;
  cursor: default;
}

.button--flat:disabled,
.button--raised:disabled,
.fab:disabled {
  background-color: rgba($wc-button-disabled-color, .12);
}
